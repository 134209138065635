export const colors = {
    // grey:   #565656,
    grey: `#818181`,
    blue: `#78BAD4`,
    yellow: `#F3CEA2`,
    green: `#B8C99D`,

    primary: `#78BAD4`,
    secondary: `#B8C99D`,

    // violet: `#8a2c87`,
    // blue: `#1c78b6`,
    // pink: `#c54680`,
    // grey: `#777`,
    // brown: `#a2978c`,

    // slide_color_menu_bar_left: `#8a2c87`,
    // slide_color_menu_bar_right: `#3E3279`,
}
